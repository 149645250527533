import React from 'react'
import PropTypes from 'prop-types'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      />
    )

    

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="prijava"
          className={`${this.props.article === 'prijava' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Prijava</h2>
          <form
            name="signup"
            method="post"
            action="/success"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="signup" />
            <div className="field half first">
              <label htmlFor="name">Ime i prezime kapitena</label>
              <input type="text" name="name" id="name" required />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" required />
            </div>
            <div className="field half first">
              <label htmlFor="phone">Telefon</label>
              <input type="text" name="phone" id="phone" required />
            </div>
            <div className="field half">
              <label htmlFor="team">Ime ekipe</label>
              <input type="text" name="team" id="team" />
            </div>
            <div className="field half first">
              <label htmlFor="company">Kompanija/organizacija</label>
              <input type="text" name="company" id="company" required />
            </div>
            <div className="field full first">
              <ul className="actions">
                <li>
                  <input type="submit" value="Prijava" className="special" />
                </li>
                <li>
                  <input type="reset" value="Reset" />
                </li>
              </ul>
            </div>
          </form>
          <div className="field full first">
            <p>
              Prijavite vašu ekipu: <br />
              • Kotizacija 4000 RSD
              <br />
              • Ekipa je prijavljena onog trenutka kada evidentiramo uplatu
              <br />
              • Kapiten prijavljuje celu ekipu (3+1) <br />• Ekipa može da bude
              sa svim muškim, svim żenskim članovima ili izmešano <br />• Igra
              se po principu 3+1, tri igrača na terenu plus jedna izmena
            </p>
            <h3>Detalji za uplatu:</h3>
            <p>
              Za uplate putem e-bankinga: <br />
              • Zarad transparentnosti molimo Vas da unesete svoje ime i prezime
              / ime firme prilikom uplaćivanja
              <br />
              • Obavezno popuniti polje "poziv na broj"
              <br />
              • Svrha uplate: Humanitarni turnir
              <br />
              • Primalac: PHP Srbija, Šeširdžijska 1, 11000 Beograd
              <br />
              • Šifra plaćanja: 287
              <br />
              • Valuta: RSD
              <br />
              • Iznos: 4.000,00 RSD
              <br />
              • Račun primaoca: 160-383598-90
              <br />• Poziv na broj: humanitarni turnir{' '}
            </p>
            <p>
              Za uplate u pošti: <br />
              • Zarad transparentnosti molimo Vas da unesete svoje ime i prezime
              / ime firme prilikom uplaćivanja
              <br />
              • Obavezno popuniti polje "poziv na broj"
              <br />
              • Svrha uplate: Humanitarni turnir
              <br />
              • Primalac: PHP Srbija, Šeširdžijska 1, 11000 Beograd
              <br />
              • Šifra plaćanja: 187
              <br />
              • Valuta: RSD
              <br />
              • Iznos: 4.000,00 RSD
              <br />
              • Račun primaoca: 160-383598-90
              <br />• Poziv na broj: humanitarni turnir{' '}
            </p>
          </div>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onOpenArticle: PropTypes.func,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
