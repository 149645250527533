import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <ul className="icons">
            <li><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/igrajzadesetku" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/igrajzadesetku/" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/igrajza10/" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
            <li><a href="mailto:nemanja@growit.tech" className="icon fa-envelope"><span className="label">Instagram</span></a></li>
        </ul>
        <p className="copyright">&copy; Igraj za desetku, 2019. Design and development: <a target="_blank" rel="noopener noreferrer" href="https://ardeo.solutions/">Ardeo Solutions</a></p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
